@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap');

.footer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  background-color: #026016; /* Verde oscuro */
  color: white;
  flex-wrap: wrap; /* Permite que las columnas se ajusten si es necesario */
  font-family: 'Merriweather', serif; /* Fuente formal y elegante */
  font-size: 14px; /* Tamaño base de letra */
  align-items: center;
  margin-top: 50px;
}

.footer-column {
  flex: 1;
  margin: 0 10px;
  min-width: 250px; /* Ancho mínimo para evitar que se hagan demasiado pequeños */
}

.footer-column h3 {
  margin-bottom: 10px;
  font-size: 1.2em; /* Tamaño más pequeño para títulos */
  font-weight: 700; /* Negrita para los encabezados */
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
  font-size: 0.9em; /* Tamaño reducido para listas */
  font-weight: 300; /* Texto más ligero para elegancia */
}

.footer p {
  line-height: 1.5;
  font-size: 0.95em; /* Tamaño reducido para párrafos */
  font-weight: 400; /* Peso intermedio para texto descriptivo */
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
  .footer {
    flex-direction: column; /* Las columnas se apilan verticalmente */
    align-items: center; /* Centra el contenido */
    text-align: center;
  }

  .footer-column {
    margin: 10px 0; /* Espaciado entre las columnas */
  }
}
