/* Estilo principal del header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #ffffff; /* Fondo blanco */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 2px solid #00796b; /* Línea decorativa */
}

/* Estilo del logo */
.logo img {
  max-height: 50px; /* Ajusta el tamaño del logo */
  width: auto;
  transition: transform 0.3s ease; /* Efecto al pasar el mouse */
}

.logo img:hover {
  transform: scale(1.1); /* Ampliación ligera */
}

/* Estilo del menú */
.menu {
  display: none; /* Ocultar por defecto en móvil */
  position: fixed;
  top: 0;
  right: 0;
  width: 100%; /* Ancho del menú móvil */
  height: 100vh;
  background-color: white; /* Fondo blanco */
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.3); /* Sombra lateral */
  z-index: 999;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%); /* Mover fuera de la pantalla por defecto */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  place-items: center;

}

.menu.active {
  transform: translateX(0); /* Mover el menú dentro de la pantalla */
}

.menu ul {
  list-style: none; /* Elimina los puntos de lista */
  padding: 0px;
  margin: 0;
  display: flex;
  flex-direction: column; /* Orientación vertical para móvil */
  gap: 30px; /* Espaciado entre elementos */
  align-items: center;
}

.menu ul li a {
  text-decoration: none; /* Elimina el subrayado */
  color: #007930; /* Verde oscuro */
  font-weight: bold;
  font-size: 16px;
  padding: 10px 15px;
  transition: color 0.3s, background-color 0.3s;
  background-color: transparent; /* Fondo inicial transparente */
  border: 2px solid transparent; /* Borde inicial transparente */
}

.menu ul li a:hover {
  color: #ffffff; /* Texto blanco al pasar el mouse */
  background-color: #007930; /* Fondo verde oscuro */
  border-color: #007930; /* Añade un borde verde oscuro */
  box-shadow: 0 2px 10px rgba(0, 121, 107, 0.3); /* Sombra en hover */
}

/* Estilo del icono de hamburguesa */
.hamburger {
  display: block; /* Mostrar el ícono en móvil */
  cursor: pointer;
}

.hamburger span {
  display: block;
  height: 2px;
  width: 25px;
  margin: 4px 0;
  background: #333;
  transition: all 0.3s ease-in-out;
}

.hamburger.active span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Versión de escritorio */
@media (min-width: 769px) {
  .menu {
    display: flex !important; /* Mostrar el menú en línea */
    position: static;
    transform: none; /* Eliminar transformaciones */
    width: auto;
    height: auto;
    background-color: transparent;
    box-shadow: none;
    flex-direction: row; /* Orientación horizontal */
    gap: 20px; /* Espaciado horizontal */
  }

  .menu ul {
    flex-direction: row; /* Orientación horizontal para escritorio */
    gap: 20px; /* Espaciado horizontal entre los elementos */
  }

  .hamburger {
    display: none; /* Ocultar el ícono de hamburguesa en escritorio */
  }
}

/* Estilos para el contenedor del submenú */
.submenu-container {
  position: relative; /* Asegura que el submenú se posicione relativo a su contenedor */
  cursor: pointer;
}

/* Estilos para el submenú */
.submenu {
  display: none; /* Ocultar submenú inicialmente */
  opacity: 0; /* Asegura que no sea visible */
  position: absolute;
  left: 0; /* Alinea el submenú con el borde izquierdo del contenedor padre */
  width: 100%; /* Hace que el submenú ocupe el mismo ancho que el contenedor padre */
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  border-radius: 0px; /* Bordes redondeados */
  list-style: none; /* Elimina los puntos de lista */
  padding: 30px; /* Espaciado vertical dentro del submenú */
  margin-top: 10px; /* Ajusta la distancia mínima entre el submenú y el contenedor padre */
}


.submenu.open {
  display: block;
  opacity: 1; /* Hazlo visible cuando esté activo */
}


/* Estilos para los enlaces del submenú */
.submenu li a {
  text-decoration: none;
  color: #00796b;
  padding: 10px 20px;
  display: block;
  margin-top: 10%;
}

.submenu li a:hover {
  background-color: #007930;
  color: white;
}

/* Asegúrate de que el título "Información 2025" esté en verde */
.submenu-title {
  color: #007930; /* Verde oscuro */
  font-size: 16px;
  padding: 15px;
  font-weight: bold; /* Texto en negrita */
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .submenu {
    position: static; /* Asegúrate de que se muestre dentro del flujo */
    box-shadow: none; /* Elimina la sombra */
    background-color: transparent; /* Fondo transparente */
    padding: 0; /* Reduce el espacio */
    align-items: center;
    margin-top: 10px; /* Espacio adicional entre el título y el submenú */
  }

  .submenu li {
    display: block; /* Asegura que los elementos ocupen todo el ancho */
    text-align: left; /* Alinea los elementos a la izquierda */
  }

  .submenu li a {
    padding: 10px; /* Espaciado cómodo */
    font-size: 14px; /* Tamaño de texto más pequeño */
    text-align: center;
  }

  .submenu-title {
    color: #007930; /* Verde oscuro */
    font-size: 16px;
    padding: 15px;
  }
}
