.solicitud-form {
    max-width: 600px; /* Ancho máximo para el formulario */
    margin: 0 auto; /* Centra el formulario */
    padding: 50px; /* Relleno para separar los campos de los bordes */
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espacio entre los elementos */
    margin-top: 30px;

  }
  
  .form-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #00796b;
    text-align: center;
    margin-bottom: 30px; /* Espacio debajo del título */
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Espacio entre los grupos de campos */
  }
  
  .form-group label {
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  /* Estilos para alinear los campos "Nombre" y "Curso" en una fila */
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Espacio entre los dos campos */
  }
  
  .form-row .form-group {
    flex: 1; /* Los dos campos ocupan el mismo espacio */
  }
  
  .form-group input,
  .form-group select {
    width: 100%; /* Asegura que los campos de entrada ocupen todo el ancho disponible */
    padding: 12px; /* Más relleno para mejorar la visibilidad */
    font-size: 1rem; /* Tamaño de fuente consistente */
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
    box-sizing: border-box; /* Para que el padding no aumente el tamaño total */
  }
  
  /* Estilo para cuando los campos están en foco */
  .form-group input:focus,
  .form-group select:focus {
    border-color: #00796b;
  }
  
  /* Mejorar la apariencia del checkbox */
  .checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .checkbox-group label {
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Botón de envío */
  .submit-button {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #00796b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #004d40;
  }
  
  .submit-button:active {
    background-color: #00332e;
  }
  /* Media Query para pantallas pequeñas (hasta 768px) */
@media (max-width: 768px) {
  .solicitud-form {
    padding: 30px; /* Reduce el padding en pantallas pequeñas */
  }

  .form-row {
    flex-direction: column; /* Apila los campos "Nombre" y "Curso" */
  }

  .form-row .form-group {
    flex: none; /* Elimina la propiedad flex para que los campos ocupen el ancho completo */
  }
}