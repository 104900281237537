.image-overlay-container {
    position: relative; /* Necesario para posicionar elementos internos */
    width: 100%; /* Ajusta según tus necesidades */
    height: 300px; /* Altura del contenedor */
    background-image: url('../../assets/images/colegio2.jpg'); /* Imagen de fondo */
    background-size: cover; /* Escala la imagen para cubrir el contenedor */
    background-position: center; /* Centra la imagen */
    display: flex; /* Usamos flexbox para centrar el título */
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
  }
  
  .image-overlay-container::before {
    content: ""; /* Pseudo-elemento para la capa de color */
    position: absolute; /* Cubre todo el contenedor */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Negro con opacidad del 50% */
    z-index: 1; /* Coloca la capa debajo del texto */
  }
  
  .overlay-title {
    position: relative; /* Asegura que el texto esté encima de la capa */
    color: white; /* Color del texto */
    font-size: 2rem; /* Tamaño del título */
    z-index: 2; /* Encima de la capa negra */
    text-align: center; /* Centrado */
    padding: 0 20px; /* Espaciado para evitar que el texto toque los bordes */
  }

  .overlay-title2
  {
    text-align: center;
  }

  /* General Container */
.container-pages {
    display: flex;
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    gap: 20px;
    background-color: #f5f6f4;
    width: 100%;
  }
  
  /* Left Section */
  .container-pages-left {
    flex: 1;
    padding: 20px;
    text-align: left;

  }
  
  .container-pages-left h2 {
    font-size: 1.5rem;
    color: #006400;
  }
  
  /* Right Section */
  .container-pages-right {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 50px;
  }
  
  /* Description */
  .container-pages-description {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .container-pages-description h3 {
    font-size: 1.2rem;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .container-pages-description p {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 10px;
  }
  
  /* Button */
  .container-pages-button {
    display: inline-block;
    background-color: #006400;
    color: #ffffff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .container-pages-button:hover {
    background-color: #004d00;
  }
  
  /* Details */
  .container-pages-details {
    background-color: #e6e6e6;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .container-pages-details h4 {
    font-size: 1.2rem;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .container-pages-details p {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 10px;
  }
  
  .container-pages-details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .container-pages-details li {
    font-size: 1rem;
    color: #333333;
    margin-bottom: 5px;
  }
  
  .container-pages-details strong {
    color: #065b19;
  }
  
  /* Media Query para pantallas pequeñas (hasta 768px) */
@media (max-width: 768px) {
    .image-overlay-container {
      height: 200px; /* Ajusta la altura para pantallas pequeñas */
    }
  
    .overlay-title {
      font-size: 1.5rem; /* Reduce el tamaño del título */
    }
  
    .container-pages {
      flex-direction: column; /* Apila las secciones verticalmente */
    }
  
    .container-pages-right {
      padding-right: 20px; /* Reduce el padding derecho */
    }
  }