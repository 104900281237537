.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #eaffe1;  /* Fondo de la página */
  flex-direction: column;
}

.icon-container {
  display: flex;
  justify-content: center;
  gap: 50px;  /* Espacio entre los iconos */
}

.icon-item {
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.icon-item:hover {
  transform: scale(1.1);
}

.icon-item img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.icon-item h3 {
  font-size: 20px;
  color: #333;
}

.Colegio {
  background-color: #fff;
  padding: 20px;
  text-align: center;
}
