.colegio-container {
    background: linear-gradient(to bottom, #eaffe1, #ffffff);
  }
  
  .colegio-container nav {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .colegio-container nav a {
    text-decoration: none;
    color: #00796b;
    font-weight: bold;
  }
  
  /* Estilo para el contenedor del slider */
  .slider-container {
    position: relative;
    width: 100%;  /* Hace que ocupe todo el ancho de la pantalla */
    height: 500px; /* Puedes ajustar la altura según tu preferencia */
    overflow: hidden; /* Evita que las imágenes se desborden */
    max-width: 100%; /* Asegura que no haya límites en el ancho */
    border-bottom: 4px solid #004d0a;
  }
  
  /* Estilo para el contenedor de la imagen */
  .slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  /* Estilo para las imágenes dentro del slider */
  .slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Hace que la imagen se ajuste al tamaño sin deformarse */
    transition: transform 0.5s ease-in-out;
  }
  
  /* Botones de navegación */
  .slider-prev-btn,
  .slider-next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 2rem;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .slider-prev-btn {
    left: 20px; /* Posiciona el botón a la izquierda */
  }
  
  .slider-next-btn {
    right: 20px; /* Posiciona el botón a la derecha */
  }
  
  /* Hover de los botones de navegación */
  .slider-prev-btn:hover,
  .slider-next-btn:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Cambia el color en hover */
  }
  
  /* Estilos responsivos */
  @media (max-width: 768px) {
    .slider-container {
      height: 300px; /* Disminuimos la altura en pantallas pequeñas */
    }
  
    .slider-prev-btn,
    .slider-next-btn {
      font-size: 1.5rem; /* Reducimos el tamaño de los botones en pantallas pequeñas */
    }
  }
  
  @media (max-width: 480px) {
    .slider-container {
      height: 200px; /* Menor altura para pantallas muy pequeñas */
    }
  
    .slider-prev-btn,
    .slider-next-btn {
      font-size: 1.2rem; /* Botones aún más pequeños */
    }
  }
  
  /* Contenedor verde debajo del slider */
  /* Contenedor general */
  .info-container {
    display: flex;
    flex-wrap: wrap; /* Permite múltiples líneas si es necesario */
    padding: 10px; /* Espacio alrededor del contenedor */
    justify-content: space-between; /* Alinea los ítems equitativamente */
    align-items: center; /* Alinea verticalmente */
    gap: 20px; /* Espacio entre filas o columnas */
  }
  /* Estilo para cada ítem */
  .info-item {
    display: flex;
    flex-direction: column; /* Los elementos dentro del ítem estarán en columna */
    align-items: center;
    justify-content: center;
    text-align: center; /* Centra el texto */
    color: white;
    padding: 20px;
    flex: 1; /* Hace que todos los ítems sean del mismo ancho */
    max-width: 250px; /* Define un ancho máximo */
    position: relative;
  }
  
  /* Estilo para el ícono */
  .info-icon {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Título de los ítems */
  .info-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
    color: #3F7D20;
  }
  
  /* Descripción */
  .info-description {
    font-size: 1rem;
    margin-top: 5px;
    line-height: 1.4;
    color: #3F7D20;
  }
  
  /* Efectos hover */
  .info-item:hover .info-icon {
    transform: scale(1.1);
  }
  
  .info-item:hover .info-title {
    color: #004d40; /* Verde más intenso al pasar el mouse */
  }
  
  .info-item:hover .info-description {
    color: #00796b; /* Verde más oscuro */
  }
  
  /* Estilos responsivos */
  @media (max-width: 768px) {
    .info-container {
      flex-direction: column; /* Los ítems se colocan en columna */
    }
  
    .info-item {
      max-width: 100%; /* Ocupan todo el ancho */
      padding: 15px 20px; /* Ajustamos el espaciado */
    }
  
    .info-item:not(:last-child)::after {
      width: 90%; /* Línea divisoria horizontal */
      height: 2px; /* Menor altura para pantallas pequeñas */
      top: auto;
      bottom: 0;
      left: 5%;
      right: auto;
    }
  }
  
  @media (max-width: 480px) {
    .info-icon {
      width: 50px; /* Íconos más pequeños */
      height: 50px;
    }
  
    .info-title {
      font-size: 1.5rem;
    }
  
    .info-description {
      font-size: 0.9rem;
    }
  }
  /* Contenedor general */
  .divisions-container {
    margin: 50px 0;
    padding-bottom: 70px;
    border-bottom: 4px solid #FFD700;
    text-align: center;
  }
  
  .divisions-title {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #2c3e50; /* Color de texto */
  }
  
  /* Contenedor de tarjetas */
  .division-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* Tarjetas */
  .division-card {
    position: relative;
    width: 250px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .division-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .division-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Información de la tarjeta (hover) */
  .card-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    text-align: center;
    padding: 10px;
    transform: none;
    transition: none; /* Eliminar transiciones */
  }
  
  .division-card:hover .card-info {
    transform:none;
  }
  
  .card-info h3 {
    font-size: 1.2rem;
    margin: 0;
  }
  
  .card-info p {
    font-size: 1rem;
    margin: 5px 0 0;
  }
  
  /* Tablet y pantallas medianas */
@media (max-width: 1024px) {
  .divisions-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .division-card {
    max-width: 200px;
  }

  .card-info h3 {
    font-size: 1rem;
  }

  .card-info p {
    font-size: 0.9rem;
  }
}

/* Celulares grandes */
@media (max-width: 768px) {
  .division-cards {
    gap: 15px;
  }

  .division-card {
    max-width: 180px;
  }

  .card-info h3 {
    font-size: 0.9rem;
  }

  .card-info p {
    font-size: 0.8rem;
  }
}

/* Celulares pequeños */
@media (max-width: 480px) {
  .divisions-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .division-card {
    width: 100%; /* Asegura que se ajuste al ancho disponible */
    aspect-ratio: 4 / 3; /* Mantiene la forma proporcional */
  }

  .card-info h3 {
    font-size: 0.8rem;
  }

  .card-info p {
    font-size: 0.7rem;
  }
}

  
  
  