.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ocupa toda la ventana */
    position: relative; /* Necesario para la posición de la pseudoclase */
    overflow: hidden; /* Evita desbordamientos */
  }
  
  .home-container::before {
    content: ""; /* Necesario para las pseudoclases */
    position: absolute; /* Permite superponer al contenedor */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/back-home.jpg'); /* Cambia esto según la ruta de tu imagen */
    background-size: cover; /* Ajusta la imagen para cubrir el área */
    background-position: center; /* Centra la imagen */
    opacity: 0.3; /* Reduce la opacidad solo del fondo */
    z-index: -1; /* Envía el fondo detrás del contenido principal */
  }
  
  .icons-container {
    display: flex;
    gap: 40px; /* Espaciado entre los íconos */
    justify-content: center;
    align-items: center;
    z-index: 1; /* Asegura que los íconos estén encima del fondo */
  }
  
  .icon {
    width: 250px;
    height: 250px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .icon:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px)
  {
  .icons-container {
    display: flex;
    gap: 40px; /* Espaciado entre los íconos */
    justify-content: center;
    align-items: center;
    z-index: 1; /* Asegura que los íconos estén encima del fondo */
    flex-direction: column;
  }
}